<template>
  <div class="content-body">
    <div class="row mt-2 mt-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Account</a></li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Receipt Voucher
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Receipt Vouchers</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_pay_in')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', { id: 'newPayIn', mode: 'create' })
          "
        >
          New Receipt
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head no-print pt-0">
      <div class="row">
        <div class="filter-input col-md-10 d-block mr-0 pr-0">
          <select v-model="params.size" @change="filterDatas" class="mr-2 mg-t-5" id="pay_in_filter_size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select v-model="params.branch" class="filter-input mr-2 mg-t-5" id="pay_in_filter_branch_type" @change="filterDatas">
            <option value="">All Branch</option>
            <option v-for="(branch,index) in payInElements.filter_branchs" :value="branch.id" :key="index">{{branch.name}}</option>
          </select>
          <select v-model="params.account_type" class="filter-input mr-2 mg-t-5" id="pay_in_filter_account_type" @change="filterDatas">
            <option value="">All Account Type</option>
            <option v-for="(type,index) in payInElements.account_types" :key="index" :value="type.id">{{type.title}}</option>
          </select>
          <select v-model="params.status" class="filter-input mr-2 mg-t-5" id="pay_in_filter_status" @change="filterDatas">
            <option value="">All Status</option>
            <option value="1">Canceled</option>
            <option value="0">Not Canceled</option>
          </select>
          <div class="mr-2 d-inline-block">
            <label class="filter-lavel mg-t-5  mb-0 mr-1">From</label>
            <input type="date" v-model="params.from" placeholder="From" class="filter-input mg-t-5  mr-1" id="pay_in_filter_date_from" @change="filterDatas">
            <label class="filter-lavel mg-t-5  mb-0 mr-1">To</label>
            <input type="date" v-model="params.to" placeholder="To" class="mr-1 filter-input mg-t-5 " id="pay_in_filter_date_to" @change="filterDatas">
          </div>
        </div>
        <div class="col-md-2 mr-0 pl-0 mg-t-5">
          <input type="text" v-model="params.searched"  class="float-right" id="pay_in_search" placeholder="Search..." @keyup="filterDatas">
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Receipt No</th>
              <th class="wd-10p">Type</th>
              <th class="wd-20p">Account Name</th>
              <th class="wd-15p">Payment Mode</th>
              <th class="wd-10p">Paid Amt.</th>
              <th class="wd-10p">Due Amt.</th>
              <th class="wd-10p text-center table-end-item no-print" v-if="checkIfAnyPermission(['view_pay_in','cancel_pay_in'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && payInDatas.length > 0">
            <tr v-for="(pays,index) in payInDatas" :key="index">
              <th scope="row" class="table-start-item">{{pageSerialNo+index}}.</th>
              <td>{{pays.date}}</td>
              <td>{{pays.receipt_no}} <span class="tx-danger" v-if="pays.status == 1">-Canceled </span></td>
              <td>{{pays.account_type_name}} </td>
              <td >{{pays.name}} <span class="tx-primary" v-if="pays.type ==1">- Advance</span></td>
              <!-- <td v-if="pays.account_type.slug == 'customer'">{{pays.account_type_name}} <span class="tx-primary" v-if="pays.type ==1">- Advance</span></td> -->
              <td>{{pays.payment_title}} <span v-if="pays.bank!= null">-{{pays.bank_name}}</span></td>
              <td>{{parseDigitForSlip(pays.paid_amount)}}</td>
              <td >{{parseDigitForSlip(pays.due_amount)}} </td>
              <td class="text-right table-end-item no-print" v-if="checkIfAnyPermission(['view_pay_in','cancel_pay_in'])">
                <a href="javascript:;" class="mr-3" :id="'payInView'+pays.id" title="View Slip" v-if="checkSinglePermission('view_pay_in')">
                  <i class="fas fa-eye tx-success"  @click="view('receiptSlip',pays.id)"></i>
                </a>
                <a href="javascript:;" class="mr-3" :id="'payInCancel'+pays.id" v-if="pays.status==0 && checkSinglePermission('cancel_pay_in')" title="Cancel Pay">
                  <i class="fas fa-times-circle tx-danger"  @click="cancel(pays.id)"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td  colspan="11" style="text-align:center;">
               {{error_message}}
              </td>
            </tr>
          </tbody>
            <tbody v-else-if="!loading && payInDatas.length == 0">
            <tr>
              <td  colspan="11" style="text-align:center;">
                No Records Found.
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td  colspan="11" style="text-align:center;">
                Loading.....
              </td>
            </tr>
          </tbody>
          
        </table>
        <Paginate v-model="page" :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1" />
        <ExpenseCreate @parent-event="getData()"/>
        <ReceiptSlip />
      </div>
    </div>
  </div>
</template>
<script>
import ExpenseCreate from "./create";
import ReceiptSlip from './receiptSlip';
import {mapGetters} from "vuex";
//import {Search} from "../../../../../mixins/search";
import Paginate from 'vuejs-paginate'
import Services from "./Services/Services";
import _ from 'lodash';
export default {
 //mixins: [Search],
  data(){
    return{
      params :{
        size:10,
        branch:'',
        account_type:'',
        today:'',
        month:'',
        from:'',
        searched:'',
        to:"",
        offset:0,
        status:'',
      },
      loading:true,
      page:1,
      error:false,
      error_message:'',
      //pagination required
      totalPageCount:0,
      pageReload:'',
      pageSerialNo:1,
    }
  },
  components: {
    ExpenseCreate, ReceiptSlip, Paginate
  },
   computed: {
    ...mapGetters([
      "icons",
      "authData",
      "dataId",
      "dataLists",
      "dataLists2",
      "today",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("payins",["payInDatas","payInElements"])
  },
  mounted() {
    this.getData();
  },
  methods: {
    //pagination method
    clickCallback: function(page) {
      console.log(page)
      if(page == 1){
        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.size) + 1 ;
      }
      this.params.offset = (page-1) * this.params.size;
      this.getData();
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    view(modal,id){
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal,mode:"read"});
    },
    filterDatas: _.debounce(function(){
      this.loading = true;
      this.page = 1;
      this.params.offset = 0;
      this.pageSerialNo = 1;
      this.disableSubmitButton=true;
      Services.getPayInFilteredDatas(this.params).then(res=>{

          this.$store.commit("payins/setPayInDatas",res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading=false;
      }).catch(err=>{
          if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
          this.error = true;
          this.loading=false;
          let error = err.response.data.error_message;
          this.setNotification(error);
      });
    },1000),
    cancel(id) {
      this.$swal({
       title: 'Do you really want to cancel this Pay-In ?',
         html:
        `<label>Remarks: </label>
        <textarea id="cancel-remarks" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Cancel it!',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm:() => {
          let remarks = document.getElementById('cancel-remarks').value;
          // cancel the payin with remarks 
           return Services.cancelPayIn(id,{'remarks':remarks}).then((response) => {
              this.payInDatas.forEach((item,index) => {
              if(item.id == id){
                    this.payInDatas[index]['status'] = 1;
                    this.payInDatas[index]['cancel_username'] = this.authData.user.name;
                    this.payInDatas[index]['cancel_date'] = this.today;
                    this.payInDatas[index]['remarks'] = remarks;
                }              
              });
          if(response.status == 200){
              let success = response.data.success_message;
              this.setNotification(success);
            }
          }).catch(error => { 
            this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if(error.response.status == 500){
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    setNotification(msg){
        console.log(msg)
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    getData(){
      this.loading =true;
      Services.getPayInFilteredDatas(this.params).then(res=>{
          this.$store.commit("payins/setPayInDatas",res.data.data.data);
          this.getPayInElements();
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading= false;
      }).catch(err=>{
        if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
        this.error = true;
        this.loading=false;
      })
    },
    getPayInElements(){
         Services.getPayInElements().then((res) => {
          this.$store.commit("payins/setPayInElements", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    },
  }, 
  watch: {
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  }
};
</script>