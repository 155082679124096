<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='newPayIn'||modalId=='customerPayin'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add New Receipt Voucher</h5>
            <a class="close" @click="$store.dispatch('modalClose','addNewItem')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.branch"
                              class="form-control"
                              id="pay_in_branch"
                              @change="getPayments"
                              :disabled="branchDisabled"
                              required
                            >
                              <option value="" disabled>Select Branch</option>
                              <option
                                v-for="(branch,index) in payInElements.branches"
                                :value="branch.id"
                                :key="index"
                              >{{branch.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Receipt No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              class="form-control"
                              id="pay_in_receipt_no"
                              placeholder="XXXX"
                              v-model="formData.receipt_no"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              v-model="formData.date"
                              class="form-control"
                              id="pay_in_date"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Account Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Account Type *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.account_type"
                              class="form-control"
                              id="pay_in_account_type"
                              required
                            >
                              <option value>Select Type</option>
                              <option
                                v-for="(type,index) in payInElements.account_types"
                                :key="index"
                                :value="type.id"
                              >{{type.title}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Account Name*</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.account_name"
                              class="form-control"
                              id="pay_in_account_name"
                              required
                              @change="changeAccountDetails()"
                            >
                              <option value>Select Account</option>
                              <option
                                v-for="(account,index) in account_heads"
                                :key="index"
                                :value="account.id"
                              >{{account.account_name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Payee Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payee Name</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="input"
                              v-model="formData.payee_name"
                              placeholder="Name"
                              class="form-control"
                              id="pay_in_name"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Phone No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="input"
                              v-model="formData.phone_no"
                              placeholder="98XXXXXXXX"
                              class="form-control"
                              id="pay_in_phone_no"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Settings</label>
                      <div class="group-attribute-container">
                        <div class="from-group-check">
                          <input
                            class="group-check-box"
                            id="payment_type"
                            type="checkbox"
                            v-model="formData.type"
                            value="0"
                          />
                          <label for="payment_type" class="ml-2">Advance Payment</label>
                        </div>
                        <div class="group-note">
                          <span>Note:</span> Please Check above if received payment is Advance
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Total Due</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.total_due"
                              class="form-control"
                              id="pay_in_total_due"
                              placeholder="NRP 0.00"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Paid Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              min="1"
                              step="any"
                              v-model="formData.paid_amount"
                              class="form-control"
                              id="pay_in_paid_amount"
                              placeholder="NRP 0.00"
                              required
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Remaining Amt.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.due_amount"
                              class="form-control"
                              id="pay_in_remaining_amount"
                              placeholder="NRP 0.00"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Payment Type</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payment Method *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.payment_method"
                              class="form-control"
                              id="pay_in_method"
                              required
                              @change="getBanks"
                            >
                              <option value disabled>Select Payment Method</option>
                              <option
                                v-for="(method,index) in payement_methods"
                                :value="method.id"
                                :key="index"
                              >{{method.title}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.bank"
                              class="form-control"
                              id="pay_in_bank_name"
                              required
                              :disabled="disabled"
                            >
                              <option value disabled>Select Bank</option>
                              <option
                                v-for="(method,index) in banks"
                                :value="method.id"
                                :key="index"
                              >{{method.bank}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <!-- Transaction id is replaced with Ref. -->
                          <div class="col-md-5 mr-0 pr-0">Ref.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="input"
                              v-model="formData.transaction_id"
                              placeholder="Payment Ref."
                              class="form-control"
                              id="pay_in_transaction_id"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              id="pay_in_description"
                              type="text"
                              v-model="formData.description"
                              style="height: 5.8rem;"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :disabled="disableSubmitButton"
                >{{ disableSubmitButton ? 'Submitting....' : 'Submit'}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <ReceiptSlip />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ReceiptSlip from "./receiptSlip";
import Services from "./Services/Services";
export default {
  components: {
    ReceiptSlip,
  },
  data() {
    return {
      disabled: true,
      disableSubmitButton: false,
      banks: [],
      payement_methods: [],
      account_heads: [],
      formData: {
        branch: "",
        receipt_no: null,
        date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        account_type: "",
        account_name: "",
        payee_name: null,
        phone_no: null,
        type: 0,
        total_due: 0,
        paid_amount: 0,
        advance_amount: 0,
        due_amount: 0,
        payment_method: "",
        bank: null,
        transaction_id: null,
        description: null,
      },
      branchDisabled:false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists2",
      "dataLists9",
      "dataLists3",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("payins", [
      "payInElements",
      "payInDatas",
      "payInReceiptData",
    ]),
    //for customer payin from  manage customer
    ...mapGetters("customers", ["customerLists"]),
  },
  mounted() {
  },
  methods: {
    submit() {
      if (this.modalMode == "create") {
        this.disableSubmitButton = true;
        // this.$store.commit("setApiUrl", "api/account/pay-in/store");
        // this.$store.commit("addData", this.formData);
        if (this.formData.paid_amount <= 0 || this.formData.paid_amount == "") {
          let error = "Paid amount cannot be 0 or empty";
          this.setNotification(error);
        } else {
          Services.createNewPayIn(this.formData)
            .then((res) => {
              this.$store.commit(
                "payins/setPayInReceiptData",
                res.data.data.data
              );
              this.$store.commit("setDataId", res.data.data.id);
              this.$store.dispatch("modalWithMode", {
                id: "afterCreateSlip",
                mode: "read",
              });
              //this.setNotification(res.data.success_message);
              this.$emit("parent-event");
              this.disableSubmitButton = false;
            })
            .catch((err) => {
              if (err.response.status == 422) {
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
              } else {
                let error = err.response.data.error;
                this.setNotification(error);
              }
              this.disableSubmitButton = false;
            });
        }
      }
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    setDate() {
      this.formData.date = this.today;
      this.formData.receipt_no = Date.now();
      this.formData.transaction_id = Date.now();
    },
    calculateAmount() {
      if (this.formData.paid_amount) {
        if (
          this.formData.account_type == this.payInElements.supplier_account_id
        ) {
          this.formData.due_amount =
            this.parseDigit(parseFloat(this.formData.total_due) +
            parseFloat(this.formData.paid_amount));
        } else if (
          this.formData.account_type == this.payInElements.customer_account_id
        ) {
          this.formData.due_amount =
           this.parseDigit( parseFloat(this.formData.total_due) -
            parseFloat(this.formData.paid_amount));
        }
      } else {
        this.formData.due_amount = this.parseDigit(this.formData.total_due);
      }
    },
    getPayInElements(){
         Services.getPayInElements()
      .then((res) => {
        this.$store.commit("payins/setPayInElements", res.data.data);
        if(res.data.data.branches.length == 1){
            this.formData.branch = res.data.data.branches[0].id;
            this.branchDisabled = true;
            this.getPayments();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    accountHeads() {
      if (this.payInElements.length != 0) {
        let details = [];
        let account_type = this.formData.account_type;
        if (account_type == 10) {
          details = this.payInElements.suppliers;
        } else if (account_type == 11) {
          details = this.payInElements.customers;
        } else {
          details = this.payInElements.accounts.filter(function (data) {
            return data.account_type == account_type;
          });
        }
        this.account_heads = { ...details };
      }
    },
    getPayments() {
      this.getReceiptNo();
      this.disabled = true;
      this.formData.bank = "";
      if (this.payInElements.payment_methods.length != 0) {
        let details = [];
        let methods = this.formData.branch;
        details = this.payInElements.payment_methods.filter(function (data) {
          return data.branch == methods;
        });
        this.payement_methods = { ...details };
        this.formData.payment_method = this.payement_methods[0].id;
      }
    },
    getBanks() {
      let payment_bank = this.formData.payment_method;
      let values = this.payInElements.payment_methods.find(function (data) {
        if (data.id == payment_bank) {
          return data.title;
        }
      });
      if (values.title.toLowerCase() == "bank") {
        if (this.payInElements.banks.length != 0) {
          this.disabled = false;
          let details = [];
          let methods = this.formData.branch;
          details = this.payInElements.banks.filter(function (data) {
            return data.branch == methods;
          });
          this.banks = { ...details };
          if (details.length > 0) {
            this.formData.bank = details[0].id;
          } else {
            this.formData.bank = "";
          }
        } else {
          this.disabled = false;
          this.formData.bank = "";
        }
      } else {
        this.disabled = true;
        this.formData.bank = "";
      }
    },
    changeAccountDetails() {
      this.formData.paid_amount = 0;
      let id = this.formData.account_name;
      let details = [];
      let account_type = this.formData.account_type;
      if (account_type == this.payInElements.supplier_account_id) {
        details = this.payInElements.suppliers;
      } else if (account_type == this.payInElements.customer_account_id) {
        details = this.payInElements.customers;
      } else {
        details = this.payInElements.accounts.filter(function (data) {
          return data.account_type == account_type;
        });
      }
      let account = details.find(function (data) {
        return data.id == id;
      });
      this.formData.total_due = account.closing_due;
    },
    getReceiptNo() {
      if (this.formData.branch) {
        Services.getReceiptNoByBranch(this.formData.branch)
          .then((res) => {
            this.formData.receipt_no = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    clearData(){
        this.formData.branch = "";
        this.formData.receipt_no = "";
        this.formData.date = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
        this.formData.account_type = "";
        this.formData.account_name = "";
        this.formData.payee_name = null;
        this.formData.phone_no = null;
        this.formData.type = 0;
        this.formData.total_due = 0;
        this.formData.paid_amount = 0;
        this.formData.advance_amount = 0;
        this.formData.due_amount = 0;
        this.formData.payment_method = "";
        this.formData.bank = null;
        this.formData.transaction_id = null;
        this.formData.description = null;
        this.disableSubmitButton = false;
    }
  },
  watch: {
    modalId(value) {
      if (value == "newPayIn") {
        this.clearData();
        this.getPayInElements();
      }
      //from customer payin from manage customer
      if (value == "customerPayin") {
        let id = this.dataId;
        this.details = this.customerLists.find(function (data) {
          return data.id == id;
        });
        this.formData.branch = "";
        this.formData.account_type = this.dataLists9.customer_account_id;
        this.paid_amount = 0;
        if (this.details) {
          this.formData.account_name = this.details.id;
          this.formData.total_due = this.parseDigit(this.details.closing_due);
          this.formData.due_amount = this.parseDigit(this.details.closing_due);
        }
      }
    },
    "formData.paid_amount"() {
      this.calculateAmount();
    },
    "formData.account_type"() {
      this.accountHeads();
    },

    "formData.total_due"(value) {
      if (value) {
        this.calculateAmount();
      }
    },
  },
};
</script>